.label {
  width: auto;
  overflow: visible;
  text-align: center;
  font-size: 14px;
  position: relative;
  transform: translate(-50%, 0);
}
.label > div {
  background: #376fd0;
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  text-align: left;
  max-width: 215px;
}
.label.short > div {
  max-width: 315px;
}
.label.short.none {
  display: none !important;
}

.short-label {
  display: flex;
}

.short-label > div:first-of-type {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 120px;
}

.label > div > hr {
  height: 1px;
  border-color: #fff;
}

.location-popup {
  min-width: 150px;
}
.location-popup h3 {
  padding: 0;
  margin: 0 0 5px 0;
}

.location-popup > div {
  font-size: 0;
}

.location-popup > div > div {
  font-size: 12px;
}
.location-popup > div > div + div {
  margin-top: 5px;
}

.gm-style-iw > button {
  display: none !important;
}
